<template>
  <v-row>
    <v-col sm="8" offset-sm="2">
      <v-form>
        <v-alert v-if="alreadyExists" text prominent type="error" icon="mdi-cloud-alert" class="mb-5">
          Ya existe un usuario con el mismo email
        </v-alert>
        <v-text-field :error-messages="nameErrors"
                      v-model="name"
                      placeholder=" "
                      label="Nombre" required>
        </v-text-field>

        <v-text-field :error-messages="emailErrors"
                      v-model="email"
                      placeholder=" "
                      label="Email" required>
        </v-text-field>

        <v-text-field :error-messages="passwordErrors"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      placeholder=" "
                      label="Contraseña" required
                      @click:append="showPassword = !showPassword"
                      >
        </v-text-field>
        <v-text-field :error-messages="repeatPasswordErrors"
                      :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="repeatPassword"
                      placeholder=" "
                      label="Repetir contraseña"
                      :type="showRepeatPassword ? 'text' : 'password'"
                      @click:append="showRepeatPassword = !showRepeatPassword"
                      required>
        </v-text-field>

        <v-container class="mt-6">
          <v-row>
            <v-col cols="12" md="3">
              <v-btn @click="$emit('back')">Anterior</v-btn>
            </v-col>
            <v-col cols="12" md="3" offset="6">
              <v-btn color="primary" @click="submit">Siguiente</v-btn>
            </v-col>
          </v-row>
        </v-container>

      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email, sameAs, minLength} from 'vuelidate/lib/validators'
import errorsMixin from "../../mixins/errors";

export default {
  name: "User",
  mixins: [validationMixin, errorsMixin],
  data: () => ({
    alreadyExists: false,
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
    showPassword:false,
    showRepeatPassword:false
  }),
  props: {
    value: {
      required: true
    }
  },
  validations: {
    name: {required},
    email: {required, email},
    password: {required, minLength: minLength(6)},
    repeatPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  mounted(){
    this.name = this.value.user.displayName
    this.email = this.value.user.email
    this.password = this.value.user.password
    this.repeatPassword = this.value.user.password
  },
  methods: {
    setAlreadyExists(){
      this.alreadyExists = true
      setTimeout(() => this.alreadyExists = false, 2500)
    },
    async submit() {
      this.alreadyExists=false
      this.$v.$touch()
      if (!this.$v.$invalid){

        try {
          const url = `wizard/users/status?email=${encodeURIComponent(this.email)}`
          const response = await this.$http.get(url)
          if(response.data.status) {
            this.setAlreadyExists()
            return
          }
        }catch (err) {
          this.setAlreadyExists()
          return;
        }
        this.value.user = {
          displayName: this.name,
          email: this.email,
          password: this.password
        }
        this.$emit("next")
        this.$emit("input", this.value)
      }
    },
  }
}
</script>
